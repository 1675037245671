import { useEffect, useRef, useState } from 'react';
import Container from './Container';
import Slider, { CustomArrowProps, Settings } from 'react-slick';
import { ExtendProps } from 'app';
import { MdArrowLeft, MdArrowRight } from 'react-icons/md';
import { NextArrow, PrevArrow } from './CarouselArrows';
/* 
export function YoutubeEmbed() {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setIsLoaded(true);
		}, 500);
	}, []);

	const scrollRef = useRef<HTMLDivElement>(null);

	const carouselSettings: Settings = {
		// speed: 10000,
		// accessibility: true,
		// arrows: true,
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
		dots: true,
		pauseOnHover: true,
		swipe: true,
		touchMove: true,
		infinite: false,
		autoplay: false,
		pauseOnFocus: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
		// variableWidth: true,;
		// swipeToSlide: true,
	};

	return (
		
	);
}
 */

function EmbedVideo({ src }: { src: string }) {
	return (
		<iframe
			className="flex-1 min-w-full h-[196px] xl:min-w-[464px] xl:h-[384px] 2xl:h-[400px]"
			src={src}
			title="YouTube video player"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			referrerPolicy="strict-origin-when-cross-origin"
			allowFullScreen
		/>
	);
}

export function YoutubeEmbed() {
	return (
		<Container>
			<div className='mt-8'>
				<b className="text-3xl font-bold mb-2 block">
					Conheça nosso Podcast
				</b>

				<h2 className="text-2xl font-bold mt-2 mb-8">
					#deixevivo Talks - conversas inspiradoras
				</h2>
			</div>

			<div className="flex gap-2 flex-wrap">
				<EmbedVideo src="https://www.youtube.com/embed/JcV5UMc5XDQ?si=OunD-S6W1DDOrI4R&amp;controls=0" />
				<EmbedVideo src="https://www.youtube.com/embed/OwVpLxUh_go?si=zKBJeXbiMBcUr2G6&amp;controls=0" />
				<EmbedVideo src="https://www.youtube.com/embed/Xlagh9AUVBU?si=n-mumfCevF_fmpzB&amp;controls=0" />
				<EmbedVideo src="https://www.youtube.com/embed/fkopEubQCBA?si=YMWrCoPp7m7vncKj&amp;controls=0" />
				<EmbedVideo src="https://www.youtube.com/embed/JucFMOIFZ1s?si=859cpjnOJnVn_3ev&amp;controls=0" />
				<EmbedVideo src="https://www.youtube.com/embed/aJ4tgR0I0zw?si=vM61Le86pUJyqZc6&amp;controls=0" />
				<EmbedVideo src="https://www.youtube.com/embed/A2QdNWbdhPw?si=bDZM4lhIf3-W7kgB&amp;controls=0" />
				<EmbedVideo src="https://www.youtube.com/embed/GQ23UVpUZAY?si=-5ZQMgT0_8MLSA0X&amp;controls=0" />
				<EmbedVideo src="https://www.youtube.com/embed/nyGRk19L1Bo?si=orp7AFHSJl0irphL&amp;controls=0" />
				<EmbedVideo src="https://www.youtube.com/embed/ul6oitTufQM?si=Fi65CMGYvOdY45V_&amp;controls=0" />
				<EmbedVideo src="https://www.youtube.com/embed/EtymRueHJIA?si=q-8kbTWchE3VueaA&amp;controls=0" />
			</div>
		</Container>
	);
}
