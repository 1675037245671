import { NextArrow, PrevArrow } from '@/components/CarouselArrows';
import NextLink from 'next/link';

import Slider, { Settings } from 'react-slick';

export default function CarouselSection() {
	const carouselSettings: Settings = {
		accessibility: true,
		arrows: true,
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
		dots: true,
		pauseOnHover: true,
		speed: 4000,
		swipe: true,
		touchMove: true,
		infinite: true,
		autoplay: true,
		pauseOnFocus: true,
	};

	const carouselImages = [
		{
			href: 'https://campaign.doare.org/campanha/dcaab4c0-1464-4d3f-b422-7fd094bf74dd/campanhadeixevivodoakids/institutodeixevivo?fbclid=PAZXh0bgNhZW0CMTEAAaY3_OlcmQHfx521lkwblVev31qyMNglb6Isi0P9lkoEmLcnGbViUVRsnPY_aem_h-ghjT4ZlVhdkGj_Bt7Hpg',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1729297224/InstitutoDeixeVivo/Carrosel/desktop-deixe-vivo-doa-kids-2024.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1729297224/InstitutoDeixeVivo/Carrosel/mobile-deixe-vivo-doa-kids-2024.png',
				alt: 'Campanha Deixe Vivo Doa Kids - 2024',
			},
		},
		{
			href: 'https://www.youtube.com/@deixevivo',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1720574884/InstitutoDeixeVivo/Carrosel/banner-youtube-desktop.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1720574885/InstitutoDeixeVivo/Carrosel/banner-youtube-mobile.png',
				alt: 'Deixe Vivo Talks - Conversas Inspiradoras',
			},
		},
		{
			href: 'https://reserva.ink/deixevivo',
			images: {
				desktop:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1720574884/InstitutoDeixeVivo/Carrosel/banner-loja-desktop.png',
				mobile:
					'https://res.cloudinary.com/oliveiradev/image/upload/v1720575026/InstitutoDeixeVivo/Carrosel/banner-loja-mobile.png',
				alt: 'Loja Deixe Vivo - Ativismo e Representatividade',
			},
		},
	];

	return (
		<>
			<section
				id="carousel-section"
				className="min-h-[500px] w-full relative mx-auto"
			>
				<Slider {...carouselSettings}>
					{carouselImages.map((v, i) => (
						<NextLink
							key={i}
							className="w-full h-full"
							target="_blank"
							href={v.href}
						>
							<figure>
								<picture>
									<source srcSet={v.images.mobile} media="(max-width: 480px)" />
									<source
										srcSet={v.images.desktop}
										media="(min-width: 1200px)"
									/>
									<img
										className="object-cover"
										src={v.images.mobile}
										alt={v.images.alt}
										width={1920}
										height={500}
									/>
								</picture>
								<figcaption className="invisible"></figcaption>
							</figure>
						</NextLink>
					))}
				</Slider>
			</section>
		</>
	);
}
